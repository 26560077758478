<template>
  <b-container class="tree_type-item py-3" fluid>
    <b-row>
      <b-col>
        <h1 v-if="$route.params.id !== 'new'">Tree Type #{{ $route.params.id }}</h1>
        <h1 v-else>New Tree Type</h1>

        <TreeTypeFormComponent
          v-if="item"
          :item="item"
        />
        <b-spinner v-else></b-spinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import TreeType from '../../api/TreeType'
import TreeTypeFormComponent from "../../components/TreeType/TreeTypeFormComponent";

export default {
  name: 'TreeTypeItem',
  components: {TreeTypeFormComponent},
  data() {
    return {
      item: null
    }
  },

  created() {
    const id = this.$route.params.id;
    if (id !== 'new') {
      TreeType.getTreeTypes(id)
        .then((response) => {
          this.item = response.data
        }).catch((error) => {
        console.log(error)
      })
    } else {
      this.item = {}
    }
  }
}
</script>

<style scoped>

</style>
