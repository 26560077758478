<template>
  <div>
    <div class="row">
      <div class="col-sm-6 col-lg-3">
        <b-form-group>
          <label class="form-label">Name</label>
          <b-form-input
            v-model="form.name"
            required
            type="text"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-form-group>
          <label class="form-label">Reference</label>
          <b-form-input
            v-model="form.reference"
            required
            type="text"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-form-group>
          <label class="form-label">Color</label>
          <b-form-input
            v-model="form.color"
            required
            type="color"
            @change="onColorChange"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-form-group>
          <label class="form-label">Density (kg/m<sup>3</sup>) <small class="text-secondary">density_kg</small></label>
          <b-form-input
            v-model="form.density_kg"
            type="number"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>


    <div class="row">
      <div class="col-sm-12">
        <b-form-group>
          <label class="form-label">Real (ex-post) CCO2 capturing Formula</label>
          <button class="btn btn-secondary btn-sm mb-3 ml-2" type="button" v-on:click="newPracticalFormula()">
            Add new
          </button>
          <!-- Using modifiers -->
          <b-button v-b-modal.test-practical-formula-modal class="btn-sm mb-3 ml-2">Test formula</b-button>

          <!-- The modal -->
          <b-modal id="test-practical-formula-modal">
            <div class="form-row">
              <div class="col-sm-6">
                <b-form-group>
                  <label class="form-label">Diameter</label>
                  <b-form-input
                    v-model="practicalFormulaForm.diameter"
                    min="0"
                    type="number"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-6">
                <b-form-group>
                  <label class="form-label">Height</label>
                  <b-form-input
                    v-model="practicalFormulaForm.height"
                    min="0"
                    type="number"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <h2 v-if="practicalFormulaForm.total" class="mt-3">{{ practicalFormulaForm.total.toFixed(3) }} Ton CCO2 per
              tree</h2>
            <template #modal-footer="{ close }">

              <b-button
                size="sm"
                variant="secondary"
                @click="close()"
              >
                Close
              </b-button>
              <b-button
                class="ml-1"
                size="sm"
                variant="primary"
                v-on:click="calcPracticalFormula()"
              >
                Calculate
              </b-button>

            </template>
          </b-modal>

          <div v-for="(practicalFormula, i) in form.practical_formulas" :key="i" class="row">
            <div class="col-md-1 col-lg-1">
              <b-form-select v-model="practicalFormula.theoreticalComparison" :options="[
          { value: 0, text: 'No compare' },
          { value: 1, text: 'Compare' },
        ]">

              </b-form-select>
            </div>
            <div class="col-md-2 col-lg-3">

              <b-form-input
                v-model="practicalFormula.stats"
                :placeholder="'Reference ' + (i + 1)"
                class="mb-3"
                type="text"
              ></b-form-input>
            </div>
            <div class="col-md-2 col-lg-3">
              <b-form-input
                v-model="practicalFormula.name"
                :placeholder="'Reference ' + (i + 1)"
                class="mb-3"
                type="text"
              ></b-form-input>
            </div>
            <div class="col-md-5 col-lg-4">
              <b-form-input
                v-model="practicalFormula.formula"
                :placeholder="'Formula ' + (i + 1)"
                class="mb-3"
                type="text"
              ></b-form-input>
            </div>
            <div class="col-md-2 col-lg-1">
              <button class="btn btn-danger btn-block mb-3" type="button" v-on:click="delPracticalFormula(i)">
                Delete
              </button>
            </div>
          </div>
        </b-form-group>
      </div>

    </div>
    <b-form-group>
      <label class="form-label">Theoric (ex-ante) CCO2/Tree Formula</label>
      <button class="btn btn-secondary btn-sm ml-2" type="button" v-on:click="renderTheoreticalFormulaStatsTable()">
        Calculate
      </button>
      <b-form-input
        v-model="form.time_formula"
        type="text"
      ></b-form-input>

    </b-form-group>
    <b-form-group>
      <button class="btn btn-secondary btn-sm mb-3" type="button"
              v-on:click="newTheoreticalAdditionalityFormula()">
        Add additionality formula
      </button>
      <div v-for="(theoreticalAdditionalityFormula, i) in form.theoretical_additionality_formulas" :key="i" class="row">
        <div class="col-md-2 col-lg-3">
          <b-form-input
            v-model="theoreticalAdditionalityFormula.name"
            :placeholder="'Reference ' + (i + 1)"
            class="mb-3"
            type="text"
          ></b-form-input>
        </div>
        <div class="col-md-2 col-lg-1">
          <b-form-input
            v-model="theoreticalAdditionalityFormula.period"
            :placeholder="'Period in years'"
            class="mb-3"
            type="text"
          ></b-form-input>
        </div>
        <div class="col-md-5 col-lg-7">
          <b-form-input
            v-model="theoreticalAdditionalityFormula.formula"
            :placeholder="'Formula ' + (i + 1)"
            class="mb-3"
            type="text"
          ></b-form-input>
        </div>
        <div class="col-md-2 col-lg-1">
          <button class="btn btn-danger btn-block mb-3" type="button"
                  v-on:click="delTheoreticalAdditionalityFormula(i)">
            Delete
          </button>
        </div>
      </div>
    </b-form-group>


    <div class="row">
      <div class="col-sm-6 mb-3">
        <b-table :items="theoreticalFormulaStats" sticky-header></b-table>
      </div>
      <div class="col-sm-6 mb-3">
        <div style="max-height: 300px;"></div>
        <line-chart :chart-data="theoreticalFormulaChartData" :options="theoreticalFormulaChartOptions"
                    :styles="myStyles"></line-chart>
      </div>
    </div>

    <small>* To calculate the average age of the trees we consider the CO2 absorbed only by the trunk of the
      tree.</small>
    <br>
    <div v-if="isWeb2UserConnected && getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator'"
         class="d-flex justify-content-end">
      <button class="btn btn-primary mr-2" @click.prevent="onSubmit">Save</button>
      <router-link class="btn btn-danger" to="/tree-types">Cancel</router-link>
    </div>
  </div>
</template>

<script>

import TreeType from '../../api/TreeType'
import {all, create} from 'mathjs'
import {mapGetters} from "vuex";
import LineChart from "../Chart/LineChart.js";
import Swal from "sweetalert2";

export default {
  name: 'TreeTypeFormComponent',
  components: {
    LineChart
  },
  props: {
    item: {
      required: false,
      type: Object
    }
  },
  computed: {
    ...mapGetters("user", ["isWeb2UserConnected", "getWeb2ActiveAccount"]),
    myStyles() {
      return {
        height: '300px',
        position: 'relative'
      }
    },
    theoretical_additionality_formulas() {
      return this.form.theoretical_additionality_formulas;
    }
  },

  data() {
    return {
      form: {
        reference: '',
        name: '',
        color: '#8cf879',
        density_kg: 0,
        practical_formulas: [],
        theoretical_additionality_formulas: [],
        volume_formula: '',
        time_formula: ''
      },
      theoreticalFormulaStats: [],
      theoreticalFormulaChartData: null,
      theoreticalFormulaChartOptions: null,
      practicalFormulaForm: {
        diameter: 0,
        height: 0,
        total: 0,
      },

    }
  },
  created() {
    if (this.item) {
      Object.assign(this.form, this.item);
      this.form.practical_formulas = this.form.practical_formulas || [];
      this.form.theoretical_additionality_formulas = this.form.theoretical_additionality_formulas || [];
      this.renderTheoreticalFormulaStatsTable();
    }
  },

  methods: {
    onSubmit() {
      if (!this.form.id) {
        TreeType.postTreeTypes(this.form)
          .then((response) => {
            this.$router.push({path: '/tree-types'})
            this.$toasted.show('The new tree type ' + response.data.name + ' has been added', {
              type: 'success',
              duration: 5000,
              theme: "bubble",
              position: "top-center"
            });
          }).catch((error) => {
          console.log(error)
        })
      } else {
        TreeType.putTreeTypes(this.form)
          .then((response) => {
            this.$router.push({path: '/tree-types'})
            this.$toasted.show('The tree type ' + response.data.name + ' has been updated', {
              type: 'success',
              duration: 5000,
              theme: "bubble",
              position: "top-center"
            });
          }).catch((error) => {
          console.log(error)
        })
      }
    },
    calcPracticalFormula() {
      this.practicalFormulaForm.total = 0;
      const math = create(all)
      for (const practicalFormulas of this.form.practical_formulas) {
        const practicalFormula = math.parse(practicalFormulas.formula).compile();
        this.practicalFormulaForm.total += parseFloat(practicalFormula.evaluate({
          diameter: this.practicalFormulaForm.diameter,
          height: this.practicalFormulaForm.height,
        }));
      }
    },
    newTheoreticalAdditionalityFormula() {
      this.form.theoretical_additionality_formulas.push({name: '', formula: '', period: 0});
    },
    delTheoreticalAdditionalityFormula(i) {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to delete this formula?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          this.form.theoretical_additionality_formulas.splice(i, 1);
          Swal.fire(
            'Deleted!',
            'The formula has been deleted.',
            'success'
          )
        }
      })
    },
    newPracticalFormula() {
      this.form.practical_formulas.push({name: '', formula: '', theoreticalComparison: 0});
    },
    delPracticalFormula(i) {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to delete this formula?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          this.form.practical_formulas.splice(i, 1);
          Swal.fire(
            'Deleted!',
            'The formula has been deleted.',
            'success'
          )
        }
      })

    },
    onColorChange() {
      // Validate hex color value
      if (/^#([0-9a-f]{3}){1,2}$/i.test(this.form.color)) {
        this.renderTheoreticalFormulaStatsTable();
      }
    },
    renderTheoreticalFormulaStatsTable() {
      this.theoreticalFormulaStats = [];
      // if (
      //     this.form.density_kg &&
      //     this.form.time_formula
      // ) {
      const math = create(all)


      const theoreticalFormula = math.parse(this.form.time_formula).compile()


      let labels = [];
      let data = [];

      let dataAdditionalOptional = {};

      for (let i = 1; i <= 150; i++) {
        let tonCCO2PerTree = theoreticalFormula.evaluate({
          density_kg: this.form.density_kg,
          year: i,
        })


        let tonCCO2AdditionalPerTree = 0;
        for (const j in this.form.theoretical_additionality_formulas) {
          const data = this.form.theoretical_additionality_formulas[j];

          if (data.formula) {
            const theoreticalAdditionalityFormula = math.parse(data.formula).compile()

            const theoreticalAdditionalityValue = theoreticalAdditionalityFormula.evaluate({
                density_kg: this.form.density_kg,
                base: tonCCO2PerTree,
                year: i,
              }) || 0;

              let key = data.name || (parseInt(j) + 1)

              if (!dataAdditionalOptional[key]) {
                dataAdditionalOptional[key] = []
              }

              tonCCO2AdditionalPerTree += theoreticalAdditionalityValue;

              dataAdditionalOptional[key].push(
                theoreticalAdditionalityValue.toFixed(5)
              )

          }
        }




        labels.push(i + ' Years');
        data.push(tonCCO2PerTree.toFixed(5));

        this.theoreticalFormulaStats.push({
          year: i,
          'Acum Ton CCO2 per tree': Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 5,
            maximumFractionDigits: 5
          }).format(tonCCO2PerTree),
          'Acum Ton CCO2 Additional per tree': Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 5,
            maximumFractionDigits: 5
          }).format(tonCCO2AdditionalPerTree)
        })

      }

      let datasets = [];

      datasets.push({
        label: 'CCO2',
        borderColor: '#4b6b41',
        backgroundColor: '#4b6b41',
        borderWidth: 3,
        hoverradius: 0,
        radius: 0,
        fill: false,
        data
      })

      for (const i in dataAdditionalOptional) {
        const data = dataAdditionalOptional[i];

        datasets.push({
          label: `Additional ${i}`,
          borderColor: '#628d3d',
          backgroundColor: '#628d3d',
          borderWidth: 3,
          hoverradius: 0,
          radius: 0,
          fill: false,
          data
        })
      }

      this.renderTheoreticalFormulaChart(labels, datasets);
    },
    renderTheoreticalFormulaChart(labels, datasets) {


      this.theoreticalFormulaChartData = {
        labels,
        datasets
      }

      this.theoreticalFormulaChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            // label: function (tooltipItem) {
            //   return new Intl.NumberFormat('de-DE', {
            //     minimumFractionDigits: 2,
            //     maximumFractionDigits: 2
            //   }).format(tooltipItem.yLabel);
            // },
            footer: (tooltipItems, data) => {
              let sumBaseLine = 0;
              let sumAdditionality = 0;
              for (const i in tooltipItems) {
                const tooltipItem = tooltipItems[i];
                if (data.datasets[i].label.includes('Additional'))
                  sumAdditionality += tooltipItem.yLabel;
                else{
                  sumBaseLine += tooltipItem.yLabel;
                }
              }
              return `T. Additional: ${Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 5,
                maximumFractionDigits: 5
              }).format(sumAdditionality)}\nT. Base Line: ${Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 5,
                maximumFractionDigits: 5
              }).format(sumBaseLine)}
`;
            }
          }
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: function (label) {
                  return new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 5,
                    maximumFractionDigits: 5
                  }).format(label);
                }
              }
            }
          ]
        },
        hover: {
          mode: 'index',
          intersect: false,
        },
      }
    }
  }
}
</script>

<style scoped>

</style>
